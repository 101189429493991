import api from './api'
import state from './state'
import useToast from '@/utils/toast'
import useErrorReason from './useErrorReason'

export default () => {
  const {
    makeToast,
  } = useToast()

  const {
    turnOnErrorReasonModal,
  } = useErrorReason()

  const queryRelatedTags = () => {
    state.isFetchingTags = true
    return api.queryRelatedTags({ seed_tag: state.seed }).then(response => {
      state.recommendedTags = response.data.data.tags
      state.tags = Object.keys(state.recommendedTags)
    }).catch(err => {
      turnOnErrorReasonModal(err)
      makeToast('danger', '태그를 가져오는데 실패했습니다')
    }).finally(() => {
      state.isFetchingTags = false
    })
  }

  return {
    queryRelatedTags,
  }
}
