<template>
  <BCard>
    <BRow>
      <BCol>
        <BFormGroup label="TAGS">
          <BFormTags v-model="state.tags" />
        </BFormGroup>
      </BCol>
    </BRow>
  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormTags,
} from 'bootstrap-vue'
import state from '../../state'
import getters from '../../getters'

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormTags,
  },
  setup() {
    return {
      state,
      getters,
    }
  },
})
</script>
