<template>
  <TagbyDetailLayout :hasLoadError="state.hasLoadError">
    <template #content>
      <TagbyTabs :tabList="tabList">
        <template #tab(filter)>
          <SeedCard />
          <ConditionCard />
        </template>

        <template #tab(result)>
          <ResultCard />
        </template>

        <template #tab(etc)>
          <EtcCard />
        </template>
      </TagbyTabs>
    </template>

    <template #side>
      <ActionCard />
      <TagbyTimelinesV3
        v-if="!getters.isCreatingMode"
        :timelineList="state.timelineList"
      />
    </template>

    <template #hidden>
      <ErrorReasonModal />
      <SetSeedModal />
    </template>
  </TagbyDetailLayout>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { getRouterIdx } from '@/utils/router'
import TagbyTabs from '@/components/TagbyTabs.vue'
import TagbyDetailLayout from '@/components/TagbyDetailLayout.vue'
import ActionCard from './components/ActionCard/Main.vue'
import SeedCard from './components/SeedCard/Main.vue'
import ConditionCard from './components/ConditionCard/Main.vue'
import EtcCard from './components/EtcCard/Main.vue'
import TagbyTimelinesV3 from '@/components/TagbyTimelinesV3.vue'
import useFetch from './useFetch'
import ErrorReasonModal from './components/Modals/ErrorReasonModal.vue'
import SetSeedModal from './components/Modals/SetSeedModal.vue'
import ResultCard from './components/ResultCard/Main.vue'
import state from './state'
import getters from './getters'
import { resetState } from './useState'

export default defineComponent({
  components: {
    TagbyDetailLayout,
    TagbyTabs,
    ActionCard,
    SeedCard,
    ConditionCard,
    EtcCard,
    TagbyTimelinesV3,
    ErrorReasonModal,
    SetSeedModal,
    ResultCard,
  },
  setup() {
    resetState()

    state.idx = getRouterIdx()

    const {
      getJobDetail,
      getResultList,
      getJobMember,
      queryPostList,
      getTimelineList,
    } = useFetch()

    if (!getters.isCreatingMode) {
      getJobDetail().then(() => getJobMember())
        .then(() => getResultList())
        .then(() => {
          if (state.channel === 'INSTAGRAM') {
            queryPostList()
          }
        })
      getTimelineList()
      state.isValidSeed = true
    }

    const tabList = [
      { key: 'filter', title: 'FILTER' },
      { key: 'result', title: 'RESULT', visible: !getters.isCreatingMode },
      { key: 'etc', title: 'ETC', visible: !getters.isCreatingMode },
    ]

    return {
      tabList,
      state,
      getters,
    }
  },
})
</script>
