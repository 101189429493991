export default () => ({
  hasLoadError: false,
  idx: null,
  memberType: null,
  memberIdx: null,
  channel: 'INSTAGRAM',
  seedType: 'ACCOUNT',
  seed: null,
  state: 'WAIT',
  condition: null,
  meta: null,
  isDeleted: false,
  createdAt: null,
  followerCountRange: null,
  tgAvgLikeRange: null,
  tgAvgCommentRange: null,
  targetRecCount: 15,
  tags: [],
  recommendedTags: {},
  resultLogList: [],
  timelineList: [],
  snapshot: null,
  isVisibleErrorReasonModal: false,
  errorReason: null,
  isSaving: false,
  isValidSeed: false,
  isFetchingTags: false,
  isLoadingExistsSeed: false,
  isVisibleSetSeedModal: false,
  memberName: null,
  memberEmail: null,
  postListByUsername: [],
  isDownloadingExcel: false,
})
