import state from './state'
import api from './api'
import useToast from '@/utils/toast'
import useErrorReason from './useErrorReason'

export default () => {
  const {
    makeToast,
  } = useToast()

  const {
    turnOnErrorReasonModal,
  } = useErrorReason()

  const turnOnSetSeedModal = () => {
    state.isVisibleSetSeedModal = true
  }
  const turnOffSetSeedModal = () => {
    state.isVisibleSetSeedModal = false
  }

  const setSeed = () => api.setSeed({ seed: state.seed }).then(() => {
    makeToast(
      'primary',
      '시드를 추가하는데 성공했습니다',
      '시드 추가가 완전히 완료되는 데 시간이 걸릴 수 있습니다. 잠시후에 다시 시도해 주세요.',
    )
  }).catch(err => {
    turnOnErrorReasonModal(err)
    makeToast('danger', '시드를 추가하는데 실패했습니다')
  })

  return {
    turnOnSetSeedModal,
    turnOffSetSeedModal,
    setSeed,
  }
}
