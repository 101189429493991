import {
  computed,
  reactive,
} from '@vue/composition-api'

import state from './state'

const isCreatingMode = computed(() => !state.idx)
const resultIdxList = computed(() => state.resultLogList.map(result => result.idx) ?? [])
const resultUsernameList = computed(() => state.resultLogList.map(result => result.sns_id) ?? [])
const resultLogItems = computed(() => state.resultLogList.map(result => {
  const username = result.sns_id
  const postList = state.postListByUsername?.[username] ?? []
  return {
    ...result,
    postList,
  }
}))
const isValidTargetRecCount = computed(() => Boolean(state.targetRecCount) && state.targetRecCount <= 100)
const isValidSeed = computed(() => Boolean(state.seed))

export default reactive({
  isCreatingMode,
  resultIdxList,
  resultUsernameList,
  resultLogItems,
  isValidTargetRecCount,
  isValidSeed,
})
