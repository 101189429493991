<template>
  <BCard>
    <BRow>
      <BCol
        cols="12"
        md="4"
      >
        <BFormGroup label="FOLLOWER COUNT">
          <TagbyRangeInputV2
            v-model="state.followerCountRange"
          />
        </BFormGroup>
      </BCol>
      <BCol
        cols="12"
        md="4"
      >
        <BFormGroup label="AVG LIKES">
          <TagbyRangeInputV2
            v-model="state.tgAvgLikeRange"
          />
        </BFormGroup>
      </BCol>
      <BCol
        cols="12"
        md="4"
      >
        <BFormGroup label="AVG COMMENTS">
          <TagbyRangeInputV2
            v-model="state.tgAvgCommentRange"
          />
        </BFormGroup>
      </BCol>
      <BCol
        cols="12"
        md="4"
      >
        <BFormGroup
          label="TARGET REC COUNT"
          invalid-feedback="Invalid value"
        >
          <BFormInput
            v-model="state.targetRecCount"
            number
            type="number"
            :state="getters.isValidTargetRecCount"
            placeholder="Input less than 100"
          />
        </BFormGroup>
      </BCol>
    </BRow>
  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import TagbyRangeInputV2 from '@/components/TagbyRangeInputV2.vue'
import state from '../../state'
import getters from '../../getters'

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    TagbyRangeInputV2,
  },
  setup() {
    return {
      state,
      getters,
    }
  },
})
</script>
