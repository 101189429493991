<template>
  <TagbyActionButton
    v-if="getters.isCreatingMode"
    :isSaving="state.isSaving"
    :isValid="true"
    variant="outline-primary"
    text="SAVE"
    @click="createJob"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyActionButton from '@/components/TagbyActionButton.vue'
import useCreate from '../../useCreate'
import state from '../../state'
import getters from '../../getters'

export default defineComponent({
  components: {
    TagbyActionButton,
  },
  setup() {
    const {
      createJob,
    } = useCreate()

    return {
      state,
      getters,
      createJob,
    }
  },
})
</script>
