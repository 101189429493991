<template>
  <TagbyActionButton
    v-if="!getters.isCreatingMode"
    :isSaving="state.isDownloadingExcel"
    :isValid="true"
    variant="outline-success"
    text="DOWNLOAD EXCEL"
    @click="downloadResultExcel"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyActionButton from '@/components/TagbyActionButton.vue'
import useDownloadExcel from '../../useDownloadExcel'
import state from '../../state'
import getters from '../../getters'

export default defineComponent({
  components: {
    TagbyActionButton,
  },
  setup() {
    const {
      downloadResultExcel,
    } = useDownloadExcel()

    return {
      state,
      getters,
      downloadResultExcel,
    }
  },
})
</script>
