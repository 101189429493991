<template>
  <div class="mb-1">
    <BRow>
      <BCol>
        <h3 class="font-weight-bold">
          <BLink
            :href="sns.landing_url"
            target="_blank"
          >
            {{ sns.uid }}
          </BLink>
          <span>
            {{ sns.username }}
          </span>
        </h3>
      </BCol>
    </BRow>
    <BRow
      v-if="sns.is_naver_influencer"
      class="mb-1"
    >
      <BCol>
        <BBadge
          variant="success"
          target="_blank"
          :href="sns.naver_url_id"
        >
          NAVER INF
        </BBadge>
        {{ sns.naver_inf_category }}
      </BCol>
    </BRow>
    <BRow class="mb-1">
      <BCol>
        <h5>
          avg_visitors: {{ sns.avg_visitors }}
        </h5>
      </BCol>
    </BRow>
    <hr>
  </div>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api'
import {
  BRow,
  BCol,
  BImgLazy,
  BBadge,
  BLink,
} from 'bootstrap-vue'

export default defineComponent({
  components: {
    BRow,
    BCol,
    BImgLazy,
    BBadge,
    BLink,
  },
  props: {
    result: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const sns = computed(() => props.result.sns)
    const postList = computed(() => props.result.postList)
    const getProfileUrl = username => `https://instagram.com/${username}/`
    const getPostingUrl = (blogId, postId) => `https://blog.naver.com/${blogId}/${postId}/`
    const getImageUrl = post => (post.is_video ? post.thumbnail_src : post.display_url)

    return {
      sns,
      postList,
      getProfileUrl,
      getPostingUrl,
      getImageUrl,
    }
  },
})
</script>
