<template>
  <TagbyDangerModal
    :visible="state.isVisibleSetSeedModal"
    title="Need to set seed"
    :content="content"
    @ok="setSeed"
    @hidden="turnOffSetSeedModal"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyDangerModal from '@/components/TagbyDangerModal.vue'
import state from '../../state'
import useSetSeedModal from '../../useSetSeedModal'

export default defineComponent({
  components: {
    TagbyDangerModal,
  },
  setup() {
    const {
      setSeed,
      turnOffSetSeedModal,
    } = useSetSeedModal()

    const content = "I can't ask for a recommendation because I don't have a seed. Would you like to request that we set a seed? It takes about 30 seconds."

    return {
      state,
      content,
      setSeed,
      turnOffSetSeedModal,
    }
  },
})
</script>
