<template>
  <BCol
    cols="12"
    sm="6"
    md="4"
  >
    <div>
      <em class="text-muted">
        {{ createdAt }}
      </em>
    </div>
    <BLink
      :href="postingUrl"
      target="_blank"
    >
      <BImgLazy
        :src="post.display_url"
        fluid
      />
    </BLink>
    <div
      ref="captionRef"
      :class="isMoreVisibleCaption ? null : 'tg-caption'"
    >
      {{ post.caption }}
    </div>
    <div
      v-if="isOverflowCaption"
      class="tg-more-btn"
      @click="toggleMoreVisible"
    >
      <span v-if="!isMoreVisibleCaption">
        ...더 보기
      </span>
      <span v-else>
        숨기기
      </span>
    </div>
  </BCol>
</template>

<script>
import {
  defineComponent,
  computed,
  ref,
} from '@vue/composition-api'
import {
  BCol,
  BLink,
  BImgLazy,
} from 'bootstrap-vue'
import moment from 'moment'

export default defineComponent({
  components: {
    BCol,
    BLink,
    BImgLazy,
  },
  props: {
    post: {
      type: Object,
    },
  },
  setup(props) {
    const createdAt = computed(() => moment.unix(props.post.taken_at_timestamp).format('YYYY-MM-DD HH:mm:ss'))

    const postingUrl = computed(() => {
      const { shortcode } = props.post
      return `https://instagram.com/p/${shortcode}/`
    })

    const isMoreVisibleCaption = ref(false)
    const toggleMoreVisible = () => {
      if (isMoreVisibleCaption.value) {
        isMoreVisibleCaption.value = false
      } else {
        isMoreVisibleCaption.value = true
      }
    }

    const captionRef = ref()
    const isOverflowCaption = computed(() => {
      if (captionRef.value == null) return false
      const {
        clientHeight, scrollHeight,
      } = captionRef.value
      return scrollHeight > clientHeight
    })

    return {
      postingUrl,
      isMoreVisibleCaption,
      toggleMoreVisible,

      captionRef,
      isOverflowCaption,

      createdAt,
    }
  },
})
</script>

<style scoped>
.tg-caption {
  max-height: 100px;
  overflow-y: hidden;
}

.tg-more-btn {
  display: inline-block;
  cursor: pointer;
}
</style>
