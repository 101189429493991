<template>
  <div class="mb-1">
    <BRow>
      <BCol>
        <h3 class="font-weight-bold d-flex">
          <BLink
            :href="profileUrl"
            target="_blank"
          >
            {{ result.sns_id }}
          </BLink>
          <span
            v-if="visibleOthers"
            class="ml-1"
          >
            유사도: {{ similarity }}
          </span>
          <div
            v-if="visibleOthers"
            class="ml-auto"
          >
            <BFormCheckbox
              v-model="result.is_unsuitable"
              @input="setResultIsUnsuitable(result.idx, $event)"
            >
              부적합
            </BFormCheckbox>
          </div>
        </h3>
      </BCol>
    </BRow>
    <BRow class="mb-1">
      <BCol>
        <h5>
          <BBadge
            variant="primary"
            pill
            class="mr-1"
          >
            follower: {{ sns.follower_count }}
          </BBadge>

          <BBadge
            variant="warning"
            pill
            class="mr-1"
          >
            avg_likes: {{ sns.tg_avg_likes.toFixed(2) }}
          </BBadge>

          <BBadge
            variant="success"
            pill
          >
            avg_comments: {{ sns.tg_avg_comments.toFixed(2) }}
          </BBadge>
        </h5>
      </BCol>
    </BRow>
    <BRow>
      <InstaResultCol
        v-for="post in postList"
        :key="post.ig_id"
        :post="post"
      />
    </BRow>
    <hr>
  </div>
</template>

<script>
import {
  defineComponent,
  computed,
} from '@vue/composition-api'
import {
  BRow,
  BCol,
  BBadge,
  BLink,
  BFormCheckbox,
} from 'bootstrap-vue'
import InstaResultCol from './InstaResultCol.vue'
import useSetResultIsSuitable from '../../useSetResultIsSuitable'

export default defineComponent({
  components: {
    BRow,
    BCol,
    BBadge,
    BLink,
    BFormCheckbox,
    InstaResultCol,
  },
  props: {
    result: {
      type: Object,
      required: true,
    },
    visibleOthers: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const sns = computed(() => props.result.sns)
    const similarity = computed(() => {
      const percent = props.result.similarity * 200
      if (percent > 99.9) {
        return '99.9'
      }
      return percent.toFixed(1)
    })
    const postList = computed(() => props.result.postList)
    const profileUrl = computed(() => `https://instagram.com/${props.result.sns_id}/`)

    const {
      setResultIsUnsuitable,
    } = useSetResultIsSuitable()

    return {
      sns,
      postList,
      profileUrl,
      similarity,
      setResultIsUnsuitable,
    }
  },
})
</script>
