import api from './api'
import state from './state'
import useToast from '@/utils/toast'
import useErrorReason from './useErrorReason'
import useSetSeedModal from './useSetSeedModal'

export default () => {
  const {
    makeToast,
  } = useToast()

  const {
    turnOnErrorReasonModal,
  } = useErrorReason()

  const {
    turnOnSetSeedModal,
  } = useSetSeedModal()

  const queryExistsSeed = () => {
    state.isLoadingExistsSeed = true
    return api.queryExistsSeed({ seed: state.seed }).then(response => {
      const { exists_seed: existsSeed } = response.data.data
      if (existsSeed) {
        state.isValidSeed = true
      } else {
        state.isValidSeed = false
      }
    }).catch(err => {
      turnOnErrorReasonModal(err)
      makeToast('danger', '시드를 확인하는데 실패했습니다')
    }).finally(() => {
      state.isLoadingExistsSeed = false
    })
  }

  const inputSeed = () => {
    state.isValidSeed = false
  }

  return {
    inputSeed,
    queryExistsSeed,
  }
}
