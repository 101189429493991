import axiosFrame from '@/utils/axiosFrame'

export default {
  getJobDetail: axiosFrame('get', '/manage/inf-recommend/job-detail/'),
  createJob: axiosFrame('post', '/manage/inf-recommend/create-job/'),
  updateJob: axiosFrame('post', '/manage/inf-recommend/update-job/'),
  getResultList: axiosFrame('get', '/manage/inf-recommend/result-list/'),
  queryExistsSeed: axiosFrame('post', '/manage/inf-recommend/query-exists-seed/'),
  getJobMember: axiosFrame('get', '/manage/inf-recommend/job-member/'),
  queryPostList: axiosFrame('post', '/manage/inf-recommend/query-post-list/'),
  getResultExcel: axiosFrame('get', '/manage/inf-recommend/result-excel/', 'blob'),
  setResultIsUnsuitable: axiosFrame('post', '/manage/inf-recommend/set-result-is-unsuitable/'),
  getTimelineList: axiosFrame('get', '/manage/inf-recommend/timeline-list/'),
  queryRelatedTags: axiosFrame('post', '/recommend/graph/find-similar-tags', null, null, true, true),
}
