import api from './api'
import state from './state'
import getters from './getters'
import useToast from '@/utils/toast'
import useErrorReason from './useErrorReason'
import useCondition from './useCondition'
import router from '@/router'

export default () => {
  const {
    makeToast,
  } = useToast()

  const {
    turnOnErrorReasonModal,
  } = useErrorReason()

  const {
    setCondition,
  } = useCondition()

  const getParams = () => {
    setCondition()

    const params = {
      idx: state.idx,
    }
    if (state.channel !== state.snapshot?.channel) {
      params.channel = state.channel
    }
    if (state.seedType !== state.snapshot?.seedType) {
      params.seed_type = state.seedType
    }
    if (state.seed !== state.snapshot?.seed) {
      params.seed = state.seed
    }
    if (state.state !== state.snapshot?.state) {
      params.state = state.state
    }
    if (JSON.stringify(state.condition) !== JSON.stringify(state.snapshot?.condition)) {
      params.condition = state.condition
    }
    if (state.channel === 'INSTAGRAM' && state.seedType === 'KEYWORDS') {
      params.meta = {
        seed_tag: state.seed,
        recommended_tags: state.recommendedTags,
        selected_tags: state.condition.tags,
      }
    }
    return params
  }

  const instaValidateValues = () => {
    // if (!state.isValidSeed) {
    //   throw Error('The seed is invalid')
    // }
    if (!getters.isValidTargetRecCount) {
      throw Error('추천 개수 값이 유효하지 않습니다')
    }
  }

  const instaKeywordsValidateValues = () => {
    if (state.tags.length === 0) {
      throw Error('태그를 하나 이상 입력해야 합니다')
    }
  }

  const naverValidateValues = () => {
    if (!getters.isValidSeed) {
      throw Error('시드가 유효하지 않습니다')
    }
    if (!getters.isValidTargetRecCount) {
      throw Error('추천 개수 값이 유효하지 않습니다')
    }
  }

  const validateValues = () => {
    if (state.channel === 'INSTAGRAM' && state.seedType === 'ACCOUNT') {
      instaValidateValues()
    } else if (state.channel === 'INSTAGRAM' && state.seedType === 'ACCOUNT') {
      instaKeywordsValidateValues()
    } else if (state.channel === 'NAVER_BLOG') {
      naverValidateValues()
    }
  }

  const createJob = () => {
    state.isSaving = true
    const params = getParams()
    try {
      validateValues()
    } catch (err) {
      makeToast('danger', '저장에 실패했습니다', err.message)
      state.isSaving = false
      return
    }
    api.createJob(params).then(() => {
      makeToast('primary', '저장에 성공했습니다')
      router.push({
        name: 'member-inf-recommend-list',
      })
    }).catch(err => {
      makeToast('danger', '저장에 실패했습니다')
      turnOnErrorReasonModal(err)
    }).finally(() => {
      state.isSaving = false
    })
  }

  return {
    createJob,
  }
}
