import state from './state'

export default () => {
  const destructInstaAccountCondition = () => {
    state.followerCountRange = state.condition.follower_count
    state.tgAvgLikeRange = state.condition.tg_avg_likes
    state.tgAvgCommentRange = state.condition.tg_avg_comments
    state.targetRecCount = state.condition.target_rec_count
  }
  const destructInstaKeywordsCondition = () => {
    state.tags = state.condition.tags
  }
  const destructNaverCondition = () => {
    state.targetRecCount = state.condition.top_n
  }

  const destructCondition = () => {
    if (state.channel === 'INSTAGRAM' && state.seedType === 'ACCOUNT') {
      destructInstaAccountCondition()
    } else if (state.channel === 'INSTAGRAM' && state.seedType === 'KEWORDS') {
      destructInstaKeywordsCondition()
    } else if (state.channel === 'NAVER_BLOG') {
      destructNaverCondition()
    }
  }

  const parseIntOrNull = value => {
    const parsedValue = parseInt(value, 10)
    if (Number.isNaN(parsedValue)) {
      return null
    }
    return parsedValue
  }

  const constructInstaAccountCondition = () => {
    const condition = {}

    if (state.followerCountRange != null) {
      condition.follower_count = state.followerCountRange.map(e => parseIntOrNull(e))
    } else {
      condition.follower_count = [null, null]
    }

    if (state.tgAvgLikeRange != null) {
      condition.tg_avg_likes = state.tgAvgLikeRange.map(e => parseIntOrNull(e))
    } else {
      condition.tg_avg_likes = [null, null]
    }

    if (state.tgAvgCommentRange != null) {
      condition.tg_avg_comments = state.tgAvgCommentRange.map(e => parseIntOrNull(e))
    } else {
      condition.tg_avg_comments = [null, null]
    }

    condition.target_rec_count = state.targetRecCount

    state.condition = condition
  }

  const constructInstaKeywordsCondition = () => {
    const condition = {}
    condition.related_keyword_dict = state.tags.reduce((p, c) => ({
      ...p,
      [c]: state.recommendedTags[c],
    }), {})
    state.condition = condition
  }

  const constructNaverCondition = () => {
    const condition = {}
    condition.top_n = state.targetRecCount
    state.condition = condition
  }

  const setCondition = () => {
    if (state.channel === 'INSTAGRAM' && state.seedType === 'ACCOUNT') {
      constructInstaAccountCondition()
    } else if (state.channel === 'INSTAGRAM' && state.seedType === 'KEYWORDS') {
      constructInstaKeywordsCondition()
    } else if (state.channel === 'NAVER_BLOG') {
      constructNaverCondition()
    }
  }

  return {
    destructCondition,
    setCondition,
  }
}
