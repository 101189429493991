import api from './api'
import state from './state'
import getters from './getters'
import useToast from '@/utils/toast'
import useErrorReason from './useErrorReason'
import useFetch from './useFetch'
import router from '@/router'

export default () => {
  const {
    makeToast,
  } = useToast()

  const {
    getJobDetail,
    getTimelineList,
  } = useFetch()

  const {
    turnOnErrorReasonModal,
  } = useErrorReason()

  const updateJob = () => {
    state.isSaving = true
    api.updateJob({
      job_idx: state.idx,
      state: state.state,
      is_deleted: state.isDeleted,
    }).then(() => {
      makeToast('primary', '저장에 성공했습니다')
      getJobDetail()
      getTimelineList()
    }).catch(err => {
      makeToast('danger', '저장에 실패했습니다')
      turnOnErrorReasonModal(err)
    }).finally(() => {
      state.isSaving = false
    })
  }

  return {
    updateJob,
  }
}
