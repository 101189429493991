<template>
  <InstaAccountCondition v-if="state.channel === 'INSTAGRAM' && state.seedType === 'ACCOUNT'" />
  <InstaKeywordsCondition v-else-if="state.channel === 'INSTAGRAM' && state.seedType === 'KEYWORDS'" />
  <NaverCondition v-else />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import InstaAccountCondition from './InstaAccountCondition.vue'
import InstaKeywordsCondition from './InstaKeywordsCondition.vue'
import NaverCondition from './NaverCondition.vue'
import state from '../../state'

export default defineComponent({
  components: {
    InstaAccountCondition,
    InstaKeywordsCondition,
    NaverCondition,
  },
  setup() {
    return {
      state,
    }
  },
})
</script>
