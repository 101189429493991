<template>
  <TagbyActionButton
    v-if="!getters.isCreatingMode"
    :isSaving="state.isSaving"
    :isValid="true"
    variant="outline-primary"
    text="SAVE"
    @click="updateJob"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyActionButton from '@/components/TagbyActionButton.vue'
import useUpdate from '../../useUpdate'
import state from '../../state'
import getters from '../../getters'

export default defineComponent({
  components: {
    TagbyActionButton,
  },
  setup() {
    const {
      updateJob,
    } = useUpdate()

    return {
      state,
      getters,
      updateJob,
    }
  },
})
</script>
