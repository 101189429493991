import moment from 'moment'
import state from './state'
import api from './api'
import useToast from '@/utils/toast'
import downloadFile from '@/utils/downloadFile'
import useErrorReason from './useErrorReason'

export default () => {
  const {
    makeToast,
  } = useToast()

  const {
    turnOnErrorReasonModal,
  } = useErrorReason()

  const downloadResultExcel = () => {
    state.isDownloadingExcel = true
    return api.getResultExcel({
      job_idx: state.idx,
    }).then(response => {
      const fileName = `[${state.seed}]_inf_recommend_${moment().format('YYYYMMDDHHmmss')}.xlsx`
      const file = new File([response.data], fileName, {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      downloadFile(file, fileName)
    }).catch(err => {
      turnOnErrorReasonModal(err)
      makeToast('danger', '엑셀 다운로드에 실패했습니다')
    }).finally(() => {
      state.isDownloadingExcel = false
    })
  }

  return {
    downloadResultExcel,
  }
}
