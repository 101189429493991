<template>
  <TagbyDangerModal
    :visible="state.isVisibleErrorReasonModal"
    title="Error reason"
    ok-only
    okTitle="ok"
    @hidden="turnOffErrorReasonModal"
  >
    <Prism
      :code="state.errorReason"
      style="max-height: 300px"
    />
  </TagbyDangerModal>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import Prism from 'vue-prism-component'
import TagbyDangerModal from '@/components/TagbyDangerModal.vue'
import state from '../../state'
import useErrorReason from '../../useErrorReason'

export default defineComponent({
  components: {
    TagbyDangerModal,
    Prism,
  },
  setup() {
    const {
      turnOffErrorReasonModal,
    } = useErrorReason()

    return {
      state,
      turnOffErrorReasonModal,
    }
  },
})
</script>
