<template>
  <BCard>
    <BRow>
      <BCol
        cols="12"
        md="4"
      >
        <BFormGroup
          label="TOP N"
          invalid-feedback="This is a required input value"
        >
          <BFormInput
            v-model="state.targetRecCount"
            number
            type="number"
            :state="getters.isValidTargetRecCount"
          />
        </BFormGroup>
      </BCol>
    </BRow>
  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import TagbyRangeInputV2 from '@/components/TagbyRangeInputV2.vue'
import state from '../../state'
import getters from '../../getters'

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    TagbyRangeInputV2,
  },
  setup() {
    return {
      state,
      getters,
    }
  },
})
</script>
