<template>
  <BCard>
    <BRow>
      <BCol
        cols="12"
        md="4"
      >
        <BFormGroup label="CHANNEL">
          <TagbySelect
            v-model="state.channel"
            :options="channelOptions"
            @input="changeChannel"
          />
        </BFormGroup>
      </BCol>
      <BCol
        cols="12"
        md="4"
      >
        <BFormGroup label="SEED TYPE">
          <TagbySelect
            v-model="state.seedType"
            :options="seedTypeOptions"
          />
        </BFormGroup>
      </BCol>
      <BCol
        v-if="state.channel === 'INSTAGRAM' && state.seedType === 'ACCOUNT'"
        cols="12"
        md="4"
      >
        <BFormGroup
          label="SEED"
          :state="state.isValidSeed"
          invalid-feedback="DB에 시드가 존재하지 않습니다."
        >
          <BInputGroup>
            <BFormInput
              v-model="state.seed"
              :state="state.isValidSeed"
              @input="inputSeed"
              @keydown.enter="queryExistsSeed"
            />
            <BInputGroupAppend>
              <BButton
                variant="outline-secondary"
                @click="queryExistsSeed"
              >
                <BSpinner
                  v-if="state.isLoadingExistsSeed"
                  small
                />
                확인
              </BButton>
            </BInputGroupAppend>
          </BInputGroup>
        </BFormGroup>
        <div
          v-if="state.isValidSeed"
          class="text-truncate"
        >
          <BLink
            :href="instaLink"
            target="_blank"
          >
            {{ instaLink }}
          </BLink>
        </div>
      </BCol>
      <BCol v-if="state.channel === 'INSTAGRAM' && state.seedType === 'KEYWORDS'">
        <BFormGroup
          label="SEED"
        >
          <BInputGroup>
            <BFormInput
              v-model="state.seed"
              @keydown.enter="queryRelatedTags"
            />
            <BInputGroupAppend>
              <BButton
                variant="outline-secondary"
                @click="queryRelatedTags"
              >
                <BSpinner
                  v-if="state.isFetchingTags"
                  small
                />
                태그추천
              </BButton>
            </BInputGroupAppend>
          </BInputGroup>
        </BFormGroup>
      </BCol>
      <BCol
        v-if="state.channel == 'NAVER_BLOG'"
        cols="12"
        md="4"
      >
        <BFormGroup
          label="SEED"
          :state="getters.isValidSeed"
          invalid-feedback="필수 입력값입니다."
        >
          <BFormInput
            v-model="state.seed"
            :state="getters.isValidSeed"
            @input="inputSeed"
          />
        </BFormGroup>
      </BCol>
    </BRow>
  </BCard>
</template>

<script>
import {
  computed,
  defineComponent,
} from '@vue/composition-api'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BButton,
  BSpinner,
  BLink,
} from 'bootstrap-vue'
import TagbySelect from '@/components/TagbySelect.vue'
import state from '../../state'
import getters from '../../getters'
import useInstaSeed from '../../useInstaSeed'
import useInstaKeywordsSeed from '../../useInstaKeywordsSeed'

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    TagbySelect,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    BSpinner,
    BLink,
  },
  setup() {
    const channelOptions = [
      'INSTAGRAM',
      'NAVER_BLOG',
    ]
    const seedTypeOptions = computed(() => {
      if (state.channel === 'INSTAGRAM') {
        return [
          'ACCOUNT',
          'KEYWORDS',
        ]
      }
      return [
        'SEARCH',
      ]
    })
    const changeChannel = () => {
      if (state.channel === 'INSTAGRAM') {
        state.seedType = 'ACCOUNT'
        state.targetRecCount = 15
      } else {
        state.seedType = 'SEARCH'
        state.targetRecCount = 50
      }
    }
    const {
      inputSeed,
      queryExistsSeed,
    } = useInstaSeed()

    const {
      queryRelatedTags,
    } = useInstaKeywordsSeed()

    const instaLink = computed(() => `https://instagram.com/${state.seed}/`)

    return {
      state,
      getters,
      channelOptions,
      seedTypeOptions,
      inputSeed,
      queryExistsSeed,
      changeChannel,
      instaLink,
      queryRelatedTags,
    }
  },
})
</script>
